*{
    margin:0;
    padding: 0;
    box-sizing: border-box;
}

.main{
    display: flex;
}
.dropdown_1 {
  position: relative;
  display: inline-block;
  
}
.dropbtn {
    background-color: #4CAF50;
    color: white;
    font-weight: bold;
    padding: 16px;
    font-size: 16px;
    border: none;
    cursor: pointer;
}
.dropbtn_offer {
    background-color: white;
    color: black;
    font-weight: bold;
    padding: 16px;
    font-size: 16px;
    border: none;
    cursor: pointer;
}

.dropdown-content {
  display: none;
  position: relative;
  background-color: inherit;
  
  
  padding: 200px 16px;
  z-index: 1;
}

.dropdown_1:hover .dropdown-content {
  display:block;
}

html, body{
    height: 100%;
    background: white
}


nav{
    width: 260px;
    height: 600px;
    position: absolute;
    top: 10px;
    bottom: 0;
    background: rgba(0,0,0,0.1);
    box-shadow: 5px 7px 10px rgba(0,0,0,.5)
}

nav ul{
    position: relative;
    list-style-type: none;
    text-decoration: none;
}

nav ul li a{
    display: flex;
    align-items: center;
    font-family: arial;
    font-size: 1.15rem;
    text-decoration: none;
    color: #000;
    /* padding: 10px 30px; */
    height: 50px;
    border-radius: 0 30px;
}

nav ul li a:hover{
    background: rgba(0,0,0,0.7);
    color: #fff;
}

nav ul ul{
    position: absolute;
    left: 220px;
    width: 200px;
    top: 0;
    display: none;
    background: white;
    box-shadow: 2px 2px 10px rgba(0,0,0,.1)


}
nav ul ul span{
    position: absolute;
    right: 20px;
    font-size: 1.5rem;

}


nav ul .dropdown{
    position: relative;
}
nav ul .dropdown:hover > ul{
    display: initial;
}
nav ul .dropdown_two ul {
    position: absolute;
    left: 200px;
    display: none;
    top: 0;
}
nav ul .dropdown_two:hover ul{
    display: initial;
}

